import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../app/hooks";
import { localStorageHandler, onSetValid } from "./LoginSlice";

export const getToken = createAsyncThunk(
    "getToken/login",
    async (data, { dispatch }) => {
        const response = await fetch(`${api}/user/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify(data),
        });
        const result = await response.json();
        console.log(result);
        if (!result.message) {
            dispatch(onSetValid(result));
            const expiredTime = new Date(new Date().getTime() + 86400 * 1000);
            //! 86400 = 24 hr
            dispatch(localStorageHandler(expiredTime));
        }
        return result;
    }
);
