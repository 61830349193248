import { useState } from "react";
import { Box, Grid, Input, InputLabel } from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkSwitch from "../../app/assets/theme/MkSwitch";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import MkAutoComplete from "../../app/assets/theme/MkAutoComplete";
import { useSelector } from "react-redux";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

function CompanyInputs({
    inputValues = null,
    handleCheck,
    checked,
    setTownship,
}) {
    const theme = useTheme();
    const { cities } = useSelector((state) => state.citiesData);
    const [nameChange, setNameChange] = useState(inputValues?.name ?? "");
    const [addressChange, setAddressChange] = useState(
        inputValues?.address.split(",")[0] ?? ""
    );
    const [phoneChange, setPhoneChange] = useState(inputValues?.phone ?? "");
    const [senderIDChange, setSenderIDChange] = useState(
        inputValues?.sms_sender_id ?? ""
    );
    const [contactPersonChange, setContactPersonChange] = useState(
        inputValues?.contact_person ?? ""
    );
    const [contactPersonPhChange, setContactPersonPhChange] = useState(
        inputValues?.contact_person_phone ?? ""
    );

    const [town, setTown] = useState(
        cities.filter((city) => city.id == inputValues?.township_id)[0]
    );
    // console.log(town);
    return (
        <Box sx={{ padding: "35px" }}>
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 12 }}
            >
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={nameChange}
                        onChange={(e) => setNameChange(e.currentTarget.value)}
                        lable="Name"
                        name="name"
                        placeholder="Enter Your Company Name"
                        focus={true}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={phoneChange}
                        onChange={(e) => setPhoneChange(e.currentTarget.value)}
                        lable="Phone"
                        name="phone"
                        placeholder="Enter Your Phone"
                        required
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={contactPersonChange}
                        onChange={(e) =>
                            setContactPersonChange(e.currentTarget.value)
                        }
                        lable="Contact Person"
                        name="contact_person"
                        placeholder="Enter Your Contact Person"
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={contactPersonPhChange}
                        onChange={(e) =>
                            setContactPersonPhChange(e.currentTarget.value)
                        }
                        lable="Contact Person Phone"
                        name="contact_person_phone"
                        placeholder="Enter Your Contact Person Phone"
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={senderIDChange}
                        onChange={(e) =>
                            setSenderIDChange(e.currentTarget.value)
                        }
                        lable="SMS Sender ID *Optional*"
                        name="sms_sender_id"
                        placeholder="Enter Your SMS Sender ID"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputLabel
                        htmlFor="grouped-cities"
                        sx={{ mb: 1, fontSize: "1rem" }}
                    >
                        Township
                    </InputLabel>
                    <MkAutoComplete
                        id="grouped-cities"
                        options={cities
                            .slice()
                            .sort(
                                (a, b) =>
                                    -b.city_name.localeCompare(a.city_name)
                            )}
                        groupBy={(option) => option.city_name}
                        getOptionLabel={(option) => option.township_name}
                        onChange={(e, newValue) => {
                            setTownship(newValue);
                            setTown(newValue);
                        }}
                        placeholder="Select your Township"
                        required={true}
                        value={town}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <InputFormComponent
                        value={addressChange}
                        onChange={(e) =>
                            setAddressChange(e.currentTarget.value)
                        }
                        lable="Address"
                        name="address"
                        placeholder="Enter Your Address"
                        required
                    />
                </Grid>

                {/* <Grid item xs={12} md={6} sx={{ alignSelf: "end" }}>
                    <ThemeProvider theme={labelTheme}>
                        <MkSwitch
                            switchColor={theme.palette.info.main}
                            label="Active"
                            checked={checked}
                            onChange={handleCheck}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </ThemeProvider>
                </Grid> */}
            </Grid>
        </Box>
    );
}

export default CompanyInputs;
