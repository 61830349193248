import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Box,
    Input,
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Avatar,
    IconButton,
} from "@mui/material";
import {
    addCompanyList,
    addCompanyUser,
    getCompanyUserByEmail,
} from "../../../features/company/CompanyApi";
import { Add, Close } from "@mui/icons-material";
import AddUserInputs from "./AddUserInputs";
import AddUserEmail from "./AddUserEmail";
import AddUserRole from "./AddUserRole";
import { removeUserByEmail } from "../../../features/company/CompanySlice";
import AlertBox from "../../../app/components/AlertBox/AlertBox";

export default function AddCompanyUser() {
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(true);
    const dispatch = useDispatch();
    const [imageData, setImageData] = useState("#");
    //get company user search by email
    const { userByEmail } = useSelector((state) => state.companyUser);

    //get email from addemail input and pass to add user input email file
    const [currentEmail, setCurrentEmail] = useState("");

    //get company id to add user
    const { companyDetail } = useSelector((state) => state.companyDetail);
    //get role id from autocomplete
    const [roleID, setRoleID] = useState(null);
    //alertState
    const [alertState, setAlertState] = useState();

    //user create success
    const [success, setSuccess] = useState(false);

    const handleClickOpen = () => {
        setCheck(true);
        setOpen(true);
    };

    const handleClose = () => {
        setCheck(true);
        setOpen(false);
        dispatch(removeUserByEmail());
        setImageData("#");
    };

    const handleCheck = (e) => {
        setCheck(e.target.checked);
    };

    const handleCheckEmail = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formEmail = formData.get("email");
        const data = {
            email: formEmail,
            company_id: companyDetail.id,
        };
        dispatch(getCompanyUserByEmail(data));
        setSuccess(false);
    };

    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            // setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    const handleAdd = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append("authority_role_id", roleID);
        formData.append("authority_company_id", companyDetail.id);
        formData.append("user_id", userByEmail?.user?.id ?? 0);
        formData.append("status", check == true ? Number(1) : Number(0));
        dispatch(addCompanyUser({ data: formData }));
        setOpen(false);
        setSuccess(true);
    };

    useEffect(() => {
        if (userByEmail?.exit) {
            if (!success) {
                setAlertState({
                    open: true,
                    vertical: "top",
                    horizontal: "right",
                    variant: "error",
                    message: "User Already exits!",
                });
            }
        }
    }, [userByEmail]);

    return (
        <>
            <Button startIcon={<Add />} onClick={handleClickOpen}>
                Add
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                component="form"
                onSubmit={
                    userByEmail?.exit || userByEmail === null
                        ? handleCheckEmail
                        : handleAdd
                }
            >
                <DialogContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <DialogContentText sx={{ padding: "20px 35px " }}>
                            Add Company User
                        </DialogContentText>
                        {userByEmail === null || userByEmail.exit ? (
                            <IconButton
                                onClick={handleClose}
                                size="large"
                                sx={{ margin: "20px 35px" }}
                            >
                                <Close fontSize="inherit" />
                            </IconButton>
                        ) : (
                            <Box>
                                <label htmlFor="contained-button-file">
                                    <Input
                                        accept="image/*"
                                        id="contained-button-file"
                                        type="file"
                                        name="profile"
                                        sx={{
                                            display: "none",
                                        }}
                                        onChange={imageInputChange}
                                    />
                                    <Avatar
                                        sx={{
                                            width: 70,
                                            height: 70,
                                            cursor: "pointer",
                                        }}
                                        variant="rounded"
                                        src={imageData}
                                    />
                                </label>
                            </Box>
                        )}
                    </Stack>
                    {userByEmail === null || userByEmail.exit ? (
                        <AddUserEmail setCurrentEmail={setCurrentEmail} />
                    ) : (
                        <AddUserInputs
                            setRoleID={setRoleID}
                            currentEmail={currentEmail}
                        />
                    )}
                </DialogContent>
                <DialogActions sx={{ padding: "15px 35px" }}>
                    {userByEmail === null || userByEmail.exit ? (
                        ""
                    ) : (
                        <Button onClick={() => dispatch(removeUserByEmail())}>
                            Back
                        </Button>
                    )}
                    <Button type="submit">
                        {userByEmail === null || userByEmail.exit
                            ? "Next"
                            : "Add user"}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertBox alertState={alertState} />
        </>
    );
}
