import {
    Avatar,
    Box,
    Button,
    Stack,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import MkSwitch from "../../../app/assets/theme/MkSwitch";
import { getUserRole } from "../../../app/helper/getUserRole";
import { imageApi } from "../../../app/hooks";
import { companyUserDetailActions } from "../../../features/company/CompanySlice";
import { statusCompanyUser } from "../../../features/company/CompanyApi";
import EditCompanyUser from "./EditCompanyUser";
import MkButton from "../../../app/assets/theme/MkButton";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

function CompanyUserList({ item, setTabIndex }) {
    const navigate = useNavigate();
    const { roles } = useSelector((state) => state.companyUserRole);
    const { companyDetail } = useSelector((state) => state.companyDetail);
    const { user } = useSelector((state) => state.loginInfo);
    const { changeUserInfo } = companyUserDetailActions;
    const theme = useTheme();
    const dispatch = useDispatch();
    const [check, setCheck] = useState(item?.status == 0 ? false : true);
    const handleCheck = (e) => {
        setCheck(e.target.checked);
        dispatch(
            statusCompanyUser({ id: item.id, company_id: companyDetail.id })
        );
    };

    const userRole = roles.find((itm) => itm.id === item.role_id);

    const loginUser = user?.id === item?.id;

    const handleUserInfo = () => {
        // setTabIndex(3);
        dispatch(changeUserInfo(item));
    };

    useEffect(() => {
        setCheck(item.status == 0 ? false : true);
        //return arrry value get value[0].id
    }, [item]);

    return (
        <TableRow>
            <TableCell>
                <Avatar
                    sx={{
                        width: 40,
                        height: 40,
                        cursor: "pointer",
                    }}
                    variant="rounded"
                    alt={`${item.first_name}`}
                    src={`${imageApi}/${item.profile}`}
                />
            </TableCell>
            <TableCell>
                <Typography
                    variant="caption"
                    component="div"
                    sx={{
                        fontWeight: "bold",
                        textAlign: "left",
                    }}
                >
                    {`${item?.first_name}` + ` ` + `${item?.last_name}`}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption" component="div" textAlign="left">
                    {item?.email}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption">{userRole?.label}</Typography>
            </TableCell>
            <TableCell>
                <ThemeProvider theme={labelTheme}>
                    <MkSwitch
                        switchColor={theme.palette.info.main}
                        checked={check}
                        onChange={handleCheck}
                        inputProps={{
                            "aria-label": "controlled",
                        }}
                        disabled={loginUser}
                    />
                </ThemeProvider>
            </TableCell>
            <TableCell align="center">
                {loginUser ? (
                    <MkButton
                        mkcolor={`linear-gradient(310deg, #2152ff, #02c6f3)`}
                        size="small"
                        onClick={() => navigate("/profile", { state: true })}
                    >
                        Edit
                    </MkButton>
                ) : (
                    <EditCompanyUser
                        item={item}
                        roles={roles}
                        userRole={userRole}
                    />
                )}
            </TableCell>
        </TableRow>
    );
}

export default CompanyUserList;
