import { useState } from "react";
import {
    Avatar,
    Box,
    Button,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MkButton from "../../app/assets/theme/MkButton";
import InputFormComponent from "../../app/components/Form/InputFormComponent";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../features/company/CompanyApi";
import { localStorageHandler } from "../../features/login/LoginSlice";
import { imageApi } from "../../app/hooks";

const labelTheme = createTheme({
    components: {
        MuiFormControlLabel: {
            label: {
                fontSize: "14px",
            },
        },
    },
});

function ProfileInputs() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { usersList } = useSelector((state) => state.companyUser);
    const { user, company_id } = useSelector((state) => state.loginInfo);
    const { companyDetail } = useSelector((state) => state.companyDetail);
    const currentUser = usersList.find((us) => us.id === user.id);
    const [email, setEmail] = useState(currentUser?.email ?? "");
    const [firstName, setFirstName] = useState(currentUser?.first_name ?? "");
    const [lastName, setLastName] = useState(currentUser?.last_name ?? "");
    const [imageData, setImageData] = useState(currentUser?.profile ?? "#");
    const [imageChange, setImageChange] = useState(false);

    //language options
    const options = [
        { code: "US", label: "English" },
        { code: "MM", label: "Myanmar" },
    ];
    const [language, setLanguage] = useState(options[0]);

    const imageInputChange = (e) => {
        const [file] = e.target.files;
        if (file) {
            setImageChange(true);
            setImageData(URL.createObjectURL(file));
        }
    };

    const handleUpdate = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        formData.append("authority_company_id", company_id);
        dispatch(updateProfile({ data: formData }));
        setImageChange(false);
        const expiredTime = new Date(new Date().getTime() + 86400 * 1000);
        dispatch(localStorageHandler(expiredTime));
    };

    return (
        <Box onSubmit={handleUpdate} component="form">
            <Box component={Paper} sx={{ padding: "25px", mt: 5 }}>
                <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                >
                    User Information
                    <Box>
                        <label htmlFor="contained-button-file">
                            <Input
                                accept="image/*"
                                id="contained-button-file"
                                type="file"
                                name="profile"
                                sx={{
                                    display: "none",
                                }}
                                onChange={imageInputChange}
                            />
                            <Avatar
                                sx={{
                                    width: 70,
                                    height: 70,
                                    cursor: "pointer",
                                }}
                                variant="rounded"
                                src={
                                    currentUser?.profile
                                        ? imageChange
                                            ? imageData
                                            : `${imageApi}/${currentUser?.profile}`
                                        : imageData
                                }
                            />
                        </label>
                    </Box>
                </Stack>
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 12 }}
                >
                    <Grid item xs={12} md={6}>
                        <InputFormComponent
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            lable="Email"
                            name="email"
                            placeholder="Enter Your Email"
                            focus={true}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputFormComponent
                            value={firstName}
                            onChange={(e) =>
                                setFirstName(e.currentTarget.value)
                            }
                            lable="First Name"
                            name="first_name"
                            placeholder="Enter Your First Name"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputFormComponent
                            value={lastName}
                            onChange={(e) => setLastName(e.currentTarget.value)}
                            lable="Last Name"
                            name="last_name"
                            placeholder="Enter Your Last Name"
                            required
                        />
                    </Grid>

                    {/* <Grid item xs={12} md={6}>
                    <InputLabel id="select-helper-label">Language</InputLabel>
                    <MkAutoComplete
                        value={language}
                        onChange={(event, newValue) => setLanguage(newValue)}
                        getOptionLabel={(option) => option.label}
                        options={options}
                        renderOption={(props, option) => (
                            <Box
                                component="li"
                                sx={{
                                    "& > img": {
                                        mr: 2,
                                        flexShrink: 0,
                                    },
                                }}
                                {...props}
                            >
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label}
                            </Box>
                        )}
                    />
                </Grid> */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "15px",
                        }}
                    >
                        <MkButton
                            mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
                            size="lg"
                            // onClick={companyEdit}
                            type="submit"
                        >
                            Update
                        </MkButton>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ProfileInputs;
