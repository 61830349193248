//! api
// export const api = "http://192.168.88.120/laravel/mmspin/public/api";
export const api = 'https://mmspin.com/api';
export const imageApi = 'https://mmspin.com';
// export const api = "http://192.168.88.120:8000/api";
// export const imageApi = "http://192.168.88.120:8000";
// export const api =
//     'http://192.168.2.111/OfficeProject/mmspin.com/public/api';
// export const imageApi =
//     'http://192.168.2.111/OfficeProject/mmspin.com/public';

//useFetch
async function useFetch({url, method, data = null, token}) {
    const generalData = (token, method) => {
        return {
            method: `${method}`,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        };
    };
    const test = generalData(token, method);
    if (data) {
        const post = JSON.stringify(data);
        test['body'] = post;
    }
    console.log(data);
    const response = await fetch(`${api}/admin/${url}`, test);
    const result = await response.json();
    console.log(result);
    return result;
}
export default useFetch;

export const usePostForm = async ({url, method, data, token}) => {
    const generalData = (token, method) => {
        return {
            method: `${method}`,
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
        };
    };
    const test = generalData(token, method);
    if (data) {
        test['body'] = data;
    }
    console.log(test);
    const response = await fetch(`${api}/admin/${url}`, test);
    const result = await response.json();
    console.log(result);
    return result;
};
