import { Box, Stack, Typography, Paper, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import MkButton from "../../app/assets/theme/MkButton";
import { useTheme } from "@mui/material/styles";
import CompanyLinkTab from "./CompanyLinkTab";
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from "react-router-dom";
import TabView from "./TabView";
import { useDispatch, useSelector } from "react-redux";
import {
    getCompanyDetail,
    getCompanyList,
    getCompanyUserRole,
} from "../../features/company/CompanyApi";
import EditCompany from "./EditCompany";
import Loading from "../../app/components/Loading/Loading";
import {
    localStorageHandler,
    onSetCompanyId,
} from "../../features/login/LoginSlice";
import { imageApi } from "../../app/hooks";

function CompanyDetail() {
    const theme = useTheme();
    const { name } = useParams();
    const nagivate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [editOpen, setEditOpen] = useState(false);
    const { company_id, company_name, user } = useSelector(
        (state) => state.loginInfo
    );
    const { companyList } = useSelector((state) => state.companyList);
    const company = companyList?.find(
        (item) => item.company_key === company_id
    );
    const { usersList } = useSelector((state) => state.companyUser);
    const dispatch = useDispatch();
    const { companyDetail, loading, error } = useSelector(
        (state) => state.companyDetail
    );

    const id = company?.company_key;
    // name.split("-diuu-")[1]
    // const Cname = name.split("-diuu-")[0] ?? companyDetail.name;
    const companyEdit = () => {
        setEditOpen(true);
    };

    //permission
    const userPermission = usersList?.find((user) => user.active == 0);

    //authorization
    const authorization =
        usersList?.filter((users) => {
            return users.id === user.id;
        }).length > 0;

    useEffect(() => {
        const expiredTime = new Date(new Date().getTime() + 86400 * 1000);
        dispatch(getCompanyDetail({ id }));
        //when company id change update localstorage company
        // dispatch(onSetCompanyId({ id: id, name: Cname }));
        dispatch(localStorageHandler(expiredTime));
        dispatch(getCompanyUserRole());
    }, [id]);

    const renderCompanyDetail = loading ? (
        <Stack direction="row" spacing={3} alignItems="center">
            <Box
                sx={{
                    borderRadius: 2.5,
                    width: 65,
                    height: 65,
                    objectFit: "cover",
                }}
            >
                <Loading
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    borderRadius={2.5}
                />
            </Box>
            <Box>
                <Typography
                    component="div"
                    variant="h3"
                    sx={{ fontWeight: "bold" }}
                >
                    <Loading
                        borderRadius={1.2}
                        variant="text"
                        width="350px"
                        height="20px"
                    />
                </Typography>
                <Stack spacing={3} direction="row">
                    <Typography variant="body2" component="div">
                        <Loading
                            borderRadius={1}
                            variant="text"
                            width="100px"
                            height="15px"
                        />
                    </Typography>
                    <Typography variant="body2" component="div">
                        <Loading
                            borderRadius={1}
                            variant="text"
                            width="100px"
                            height="15px"
                        />
                    </Typography>
                    <Typography variant="body2" component="div">
                        <Loading
                            borderRadius={1}
                            variant="text"
                            width="100px"
                            height="15px"
                        />
                    </Typography>
                </Stack>
                <Typography variant="body2" component="div">
                    <Loading
                        borderRadius={1}
                        variant="text"
                        width="100px"
                        height="15px"
                    />
                </Typography>
            </Box>
        </Stack>
    ) : (
        <Stack direction="row" spacing={3} alignItems="center">
            <Avatar
                sx={{
                    borderRadius: 2.5,
                    width: 74,
                    height: 74,
                    objectFit: "cover",
                }}
                src={`${imageApi}/${companyDetail?.company_logo}`}
                alt={companyDetail?.name}
            />
            <Box>
                <Typography component="div" variant="subtitle1">
                    {companyDetail?.name}
                </Typography>
                <Stack spacing={3} direction="row">
                    <Typography variant="subtitle2" component="div">
                        {companyDetail?.contact_person}
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {companyDetail?.phone}
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        {companyDetail?.address}
                    </Typography>
                </Stack>
                <Typography
                    sx={{ color: "#3535c3" }}
                    component="div"
                    variant="subtitle2"
                >
                    {companyDetail?.sms_sender_id}
                </Typography>
            </Box>
        </Stack>
    );

    if (error) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                <Typography component="h1" variant="h1">
                    OHHHH! {error}
                </Typography>
            </Box>
        );
    }

    if (user.id === userPermission?.id) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                }}
            >
                <Typography component="h1" variant="h1">
                    OHHHH! You need permission to enter.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ minHeight: "calc(100vh - 110px)" }}>
            <Box sx={{ padding: "25px 25px 0px 25px" }} component={Paper}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        {renderCompanyDetail}
                        <Stack direction="row" spacing={2} alignItems="center">
                            {/* <MkButton
                                mkcolor={`linear-gradient(310deg, ${theme.palette.gradientSuccess.main}, ${theme.palette.gradientSuccess.light})`}
                                size="small"
                            >
                                Enable
                            </MkButton> */}
                            <MkButton
                                mkcolor={`linear-gradient(310deg, ${theme.palette.gradientDark.main}, ${theme.palette.gradientDark.light})`}
                                size="small"
                                onClick={companyEdit}
                            >
                                Edit
                            </MkButton>
                        </Stack>
                    </Stack>
                </Box>
                <CompanyLinkTab tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </Box>
            <Box>
                <TabView tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </Box>

            {/*  Edit company model box */}
            <EditCompany
                open={editOpen}
                setOpen={setEditOpen}
                companyDetail={companyDetail ?? []}
            />
        </Box>
    );
}

export default CompanyDetail;
