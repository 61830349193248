import { InputBase, Autocomplete, TextField } from "@mui/material";
import { forwardRef } from "react";
import RootAutoComplete from "./RootAutoComplete";

const MkAutoComplete = forwardRef(
    (
        { placeholder = "", required = false, fullWidth = false, ...rest },
        ref
    ) => {
        return (
            <RootAutoComplete
                ref={ref}
                disablePortal
                {...rest}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        required={required}
                    />
                )}
                ownerState={{ fullWidth }}
            />
        );
    }
);

export default MkAutoComplete;
