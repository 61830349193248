import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "./LoginApi";
import { removeToken } from "../logout/LogoutApi";
import { updateProfile } from "../company/CompanyApi";

let logoutTimer;
const initialLoginData = {
    user: {},
    token: null,
    error: null,
    company_id: null,
    company_name: null,
};

const loginData = createSlice({
    name: "loginInfo",
    initialState: {
        ...initialLoginData,
    },
    reducers: {
        onSetValid: (state, { payload }) => {
            state.user = payload.user;
            state.token = payload.token;
            state.company_id = payload.company_id;
            state.company_name = payload.company_name;
        },
        onSetCompanyId: (state, { payload }) => {
            console.log(payload);
            state.company_id = payload.id;
            state.company_name = payload.name;
        },
        onLogoutHandler: (state) => {
            localStorage.removeItem("auth");
            if (logoutTimer) {
                clearTimeout(logoutTimer);
            }
            state.user = {};
            state.token = null;
            state.company_id = null;
            state.company_name = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getToken.fulfilled, (state, { payload }) => {
                if (payload.message) {
                    state.error = payload.message;
                }
            })
            .addCase(removeToken.fulfilled, (state, { payload }) => {
                //from logout api
                state.error = null;
                loginData.caseReducers.onLogoutHandler(state);
            })
            .addCase(updateProfile.fulfilled, (state, { payload }) => {
                state.user.first_name = payload.first_name;
                state.user.last_name = payload.last_name;
                state.user.email = payload.email;
            });
    },
});

export const loginInfo = loginData.reducer;
export const { onSetValid, onLogoutHandler, onSetCompanyId } =
    loginData.actions;

// calculate the expired time
export const calExpiredTime = (expiredTime) => {
    const currentTime = new Date().getTime();
    const exTime = new Date(expiredTime).getTime();
    return exTime - currentTime;
};

export default calExpiredTime;

//* for authentication reload
export const reloadHandler = () => async (dispatch) => {
    let auth = localStorage.getItem("auth");
    if (auth) {
        auth = JSON.parse(auth);
        dispatch(
            onSetValid({
                user: auth.user,
                token: auth.token,
                company_id: auth.company_id,
                company_name: auth.company_name,
            })
        );
        //* checking expire time
        dispatch(localStorageHandler(auth.expiredTime));
    }
};

// localstorage function
export const localStorageHandler =
    (expiredTime) => async (dispatch, getState) => {
        const { loginInfo } = getState();

        if (loginInfo.token) {
            localStorage.setItem(
                "auth",
                JSON.stringify({
                    token: loginInfo.token,
                    user: loginInfo.user,
                    company_id: loginInfo.company_id,
                    company_name: loginInfo.company_name,
                    expiredTime: expiredTime,
                })
            );

            const remainingTime = calExpiredTime(expiredTime);
            //* logout after remainingTime
            logoutTimer = setTimeout(
                () => dispatch(onLogoutHandler()),
                remainingTime
            );
        }
    };
