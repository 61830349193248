import { FilterList } from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import AddCompany from "../../../components/company/AddCompany";

function TableToolbar({ children }) {
    return (
        <Toolbar>
            <Typography sx={{ flex: "1 1 100%" }} variant="h4" component="div">
                {children}
            </Typography>

            {/* <AddCompany /> */}
        </Toolbar>
    );
}

export default TableToolbar;
