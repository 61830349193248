import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import UserTabView from "./user/UserTabView";
import CompanyUserInfo from "./user/CompanyUserInfo";
import CampaignTabView from "./campaign";
import CampaignInfo from "./campaign/CampaignInfo";
import PromotionInfo from "./promotion/PromotionInfo";
import CouponInfo from "./coupon/CouponInfo";
import TandcInfo from "./tandc/TandcInfo";
import { QRCodeInfo } from "./qrcode/QRCodeInfo";
import Embedded from "./embedded/Embedded";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabView({ tabIndex, setTabIndex }) {
    const theme = useTheme();

    return (
        <Box>
            <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                {/* <h1>hay</h1> */}
                <UserTabView setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                <CampaignTabView setTabIndex={setTabIndex} />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={2} dir={theme.direction}>
                <Typography variant="body2">This is Activity</Typography>
            </TabPanel> */}
            <TabPanel value={tabIndex} index={3} dir={theme.direction}>
                <CompanyUserInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={4} dir={theme.direction}>
                <CampaignInfo tabIndex={tabIndex} setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={5} dir={theme.direction}>
                <PromotionInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={6} dir={theme.direction}>
                <CouponInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={7} dir={theme.direction}>
                <TandcInfo tabIndex={tabIndex} />
            </TabPanel>
            <TabPanel value={tabIndex} index={8} dir={theme.direction}>
                <QRCodeInfo />
            </TabPanel>
            <TabPanel value={tabIndex} index={9} dir={theme.direction}>
                <Embedded />
            </TabPanel>
        </Box>
    );
}

export default TabView;
